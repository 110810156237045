import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"

import styles from "./index.module.css"

let mailChimpSignupHtml = `
<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<div id="mc_embed_signup">
<form action="https://gmail.us20.list-manage.com/subscribe/post?u=a8aefa9cf12fa1b13c1209675&amp;id=44bcd0b1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
<div id="mc_embed_signup_scroll">
<div class="indicates-required"><span class="asterisk">*</span> 必須項目</div>
<div class="mc-field-group">
<label for="mce-EMAIL">メールアドレス  <span class="asterisk">*</span>
</label>
<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
<label for="mce-NAME">氏名 （省略可） </label>
<input type="text" value="" name="NAME" class="" id="mce-NAME">
</div>
<div class="mc-field-group size1of2">
<label for="mce-MMERGE1-month">誕生日（省略可） </label>
<div class="datefield">
<span class="subfield monthfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="MM" size="2" maxlength="2" name="MMERGE1[month]" id="mce-MMERGE1-month"></span> /
<span class="subfield dayfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="DD" size="2" maxlength="2" name="MMERGE1[day]" id="mce-MMERGE1-day"></span>
<span class="small-meta nowrap">( 月 / 日 )</span>
</div>
</div>
<div class="mc-field-group size1of2">&nbsp;</div>
<div id="mce-responses" class="clear">
<div class="response" id="mce-error-response" style="display:none"></div>
<div class="response" id="mce-success-response" style="display:none"></div>
</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_a8aefa9cf12fa1b13c1209675_44bcd0b1f0" tabindex="-1" value=""></div>
<div class="clear" style="display: block;"><input type="submit" value="ニュースレターを購読" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
</div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[2]='NAME';ftypes[2]='text';fnames[1]='MMERGE1';ftypes[1]='birthday'; /*
* Translated default messages for the jQuery validation plugin.
* Locale: JA (Japanese)
*/
$.extend($.validator.messages, {
required: "このフィールドは必須です。",
remote: "このフィールドを修正してください。",
email: "有効なEメールアドレスを入力してください。",
url: "有効なURLを入力してください。",
date: "有効な日付を入力してください。",
dateISO: "有効な日付（ISO）を入力してください。",
number: "有効な数字を入力してください。",
digits: "数字のみを入力してください。",
creditcard: "有効なクレジットカード番号を入力してください。",
equalTo: "同じ値をもう一度入力してください。",
accept: "有効な拡張子を含む値を入力してください。",
maxlength: $.validator.format("{0} 文字以内で入力してください。"),
minlength: $.validator.format("{0} 文字以上で入力してください。"),
rangelength: $.validator.format("{0} 文字から {1} 文字までの値を入力してください。"),
range: $.validator.format("{0} から {1} までの値を入力してください。"),
max: $.validator.format("{0} 以下の値を入力してください。"),
min: $.validator.format("{1} 以上の値を入力してください。")
});}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->
`

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="ボクラノアミモノ"/>

        <section className="bg-white" id="about">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p>編み物は自由です。</p>
                <p>編み物は心を静かにしてくれます。</p>
                <p>編み物はどこででもできます。</p>
                <p>編み物は創造です。</p>
                <p>編み物はリズムです。</p>
                <p>編み物は一人でもできます。</p>
                <p>編み物は人と人とをつなげます。</p>
                <p>男の子にも女の子にも。</p>
                <p>お父さんにもお母さんにも。</p>
                <p>お爺さんにもお婆さんにも。</p>
                <p>編み物をすべての人に。</p>
                <hr className="divider my-5"/>
                <p>ボクラノアミモノ宣言　2019年10月27日</p>
                <div className="my-5">
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-light" id="college">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">

                <h2 className="mt-0">あみものエキスポonline<span role="img" aria-label=""></span></h2>

                <a href="https://amiexpo.bokurano-amimono.com/" target="_blank" >
                  <img className="col-8 mx-auto" src={'amiexpo.jpg'} alt="" />
                </a>

              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-white" id="college">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">

                <h5 className="mt-0">ボクラノアミモノ企画</h5>
                <h2 className="mt-0">あみものカレッジ<span role="img" aria-label="">🎓</span></h2>
                <h2 className="mt-0">「編み物の魔法がとけるとき」</h2>
                <hr className="divider my-4"/>

                <p className="text-muted text-left">
                  羊の毛は、わずか10センチほどの短さ。<br/>
                  それが集まって糸となり、編まれることで面となります。<br/>
                  太古の昔から、ヒトは短い繊維に魔法をかけて、寒さから身を守り、暮らしを豊かにしてきました。<br/>
                </p>
                <p className="text-muted text-left">
                  幾千年もの時間をかけて、編み物の魔法は発展を続けてきました。<br/>
                その間に、繊維にかけていたはずの魔法が、いつからか、少しずつ、ヒトの心を絡め取り、巻き込んでしまったのかもしれません。<br/>
                  「編み物はおばあちゃんの趣味」<br/>
                「編み物は難しい」<br/>
                  「男のひとが編み物をするなんて」<br/>
                </p>
                <p className="text-muted text-left">
                  あみものカレッジ最初の講義「編み物の魔法がとけるとき」では、編み物の魔法を解きほぐしながら、まっさらな気持ちで「編み物」に向き合ってみたいと思います。
                </p>
                <p className="text-muted text-left">
                  校長 横山起也 ＊ 講師 はやみずゆうと ＊ 助手 Katy
                </p>
                <p className="text-muted text-left">
                  《こんな人にオススメ》
                </p>
                <ul className="text-muted text-left">
                  <li>編み物が好きな人</li>
                  <li>編み物はしたことがないけれど興味がある人</li>
                  <li>編み物をする家族や知り合いが、どうして使いきれないほどの毛糸を買い込んでくるのか気になる人</li>
                </ul>

                <p className="text-muted text-center">
                  <a href="https://min.togetter.com/bqC6ASE">2019/12/07 開催時の様子</a>
                </p>

                <p className="text-muted text-center">
                  <a href="https://min.togetter.com/0wgv5vd">2020/01/25 開催時の様子</a>
                </p>

              </div>
            </div>
          </div>
        </section>

        <section className="page-section"  id="newsletter">
          <div className="container">
            <div className="row py-5">
              <div className="col-lg-12 text-center">
                <h3>ボクラノアミモノをフォローする</h3>

                <p>
                  ソーシャルメディア上でボクラノアミモノに関する最新の話題を随時発信してゆきます。<br/>
                  皆さん一人ひとりに知って頂くことが、ボクラノアミモノの大きな支えになります。<br/>
                  気軽にフォローして頂けると、とても嬉しいです。
                </p>

                <ul className="list-inline social-buttons" style={{fontSize: `3em`}}>
                  <li className="list-inline-item">
                    <a href="https://twitter.com/BokuranoAmimono/">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://instagram.com/BokuranoAmimono/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 text-center">
                <h3>ボクラノアミモノ ニュースレター</h3>
                <p>ボクラノアミモノに関するお知らせをメールでお届けします（月1回程度）。</p>
                <div dangerouslySetInnerHTML={{ __html: mailChimpSignupHtml }}/>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section" id="material">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">資料</h2>
                <hr className="divider my-4"/>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 mr-auto text-center">
                <h4>プロジェクトシート（B5印刷用）</h4>
              </div>

              <div className="col-lg-2 mr-auto text-left">
              </div>
              <div className="col-lg-8 mr-auto text-left">
                <p>取り組んでいる編み物プロジェクトのメモに使うことのできるシートです。</p>
                <p>
                葉書よりも一回り大きいくらいのサイズなので、プロジェクトバッグに一緒に入れておくと便利です。
                2穴リングファイル用の穴あけ位置マーク済です。
                </p>
                <p>
                B5用紙に印刷して半分に切って使ってください。</p>
                <p>
                  <a href={'material/projectsheet_jp.pdf'}>日本語版<br />
                  <img className="mx-auto img-thumbnail" src={'material/projectsheet_jp.png'} alt="" /></a>
                </p>
                <p>
                <a href={'material/projectsheet.pdf'}>英語版<br />
                <img className="mx-auto img-thumbnail" src={'material/projectsheet-img.png'} alt="" /></a>
                </p>
              </div>
              <div className="col-lg-2 mr-auto text-left">
              </div>

            </div>
          </div>
        </section>


        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">お問い合わせ</h2>
                <hr className="divider my-4"/>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12 mr-auto text-center">
                ボクラノアミモノ事務局<br/>
                <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                <a className="d-block" href="mailto:bokuranoamimono@gmail.com">bokuranoamimono@gmail.com</a>
              </div>
            </div>
          </div>
        </section>

        <section className="page-section bg-dark text-white" id="team">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'haya.jpg'} alt="はやみず" />
                  <h4>はやみず ゆうと</h4>
                  <p>編みドクター</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/hayamiz/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/hayamiz/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src="kinoko.jpg" alt="横山" />
                  <h4>横山 起也</h4>
                  <p>編みキノコDJ</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/amikinokokaigi">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/yokoyama___tatsuya/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'katy.jpg'} alt="Katy" />
                  <h4>Katy</h4>
                  <p>世話役</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/KatyKeito">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/knit_me_by_mayds/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
 
              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'keikoto.jpg'} alt="蛍光灯ニット" />
                  <h4>蛍光灯ニット</h4>
                  <p>あみものエバンジェリスト</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/keikoto_knit">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'hiroshi.jpg'} alt="ひろし" />
                  <h4>ひろし</h4>
                  <p>謎</p>
                </div>
              </div>

              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'yuko.jpg'} alt="yuko*" />
                  <h4>yuko*</h4>
                  <p>黒田製麺所</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/teafor_2">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a href="https://instagram.com/100colorspencil/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-sm-4">
                <div className={styles.teamMember}>
                  <img className="mx-auto rounded-circle" src={'michiyotsu3.jpg'} alt="みちよつ" />
                  <h4>みちよつ</h4>
                  <p>自由型ハンドクラフト作家</p>
                  <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                      <a href="https://twitter.com/michi42moto">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
          </div>
        </section>

      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
